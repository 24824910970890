import React from "react";
import {
    CardTitle,
    CardBody,
    Container,
    Row,
    Accordion,
    AccordionSection
} from "ui-library";
import { IconButton } from '../../IconComponent';
import { VideoTabletCol } from '../../Utils.js'

let PowerBIReportBriefs = () => {

    let reportbriefs = require('./ReportBriefs.json');
    const { ReportBriefsOverviewTitle, ReportBriefsOverviewText, ReportBriefsLinks } = reportbriefs[0];

    return (
        <>
            <CardTitle title={ReportBriefsOverviewTitle} alignment="left" />
            <CardBody alignment="left">
                <p>
                    <p>
                        {ReportBriefsOverviewText}
                    </p>
                    <p>
                        {ReportBriefsLinks.map((link) => {
                            return <IconButton item={link} cssClass={'resourceIcon'} iconSize={28} />;
                        })}
                    </p>
                </p>

            </CardBody>
        </>

    )
}

export { PowerBIReportBriefs };