import { React, useEffect, useState } from "react"
import ClientSearch from "./ClientSearch"
import { getQueryString } from './Utils.js'

let Search = () => {

    const searchWord = getQueryString("searchQuery") ?? "";
    let nhiFaqFile = require('./Help/FAQs/NHIFAQs.json');
    let rpiFaqFile = require('./Help/FAQs/RPIFAQs.json');
    let powerBIFaqFile = require('./Help/FAQs/PowerBIFAQs.json');
    let authenticationFaqFile = require('./Help/FAQs/AuthenticationFAQs.json');
    let endPointLinks = [];
    const apiDataFolder = "../../public/APIs/";
    const regex = /(<([^>]+)>)/gi;

    // Build 'Home' index
    const homeLinks = require('./searchIndex.json').contents.map(item => {
        return {
            Label: item.Label, SubTitle: item.SubTitle, URL: item.URL,
            Extension: item.Extension, Filter: "Home"
        };
    });


    // Build 'Training & Resources' index
    let RepAnalyticsLinks = require('./Training/Workgroups/ReportAnalytics/ReportAnalytics.json')[0].WorkgroupLinks?.map(link => { return link.Links }).flat(1);
    let AppFlowLinks = require('./Training/Workgroups/ApplicantFlow/ApplicantFlow.json')[0].WorkgroupLinks?.map(link => { return link.Links }).flat(1);
    let InterconnWGLinks = require('./Training/Workgroups/Interconnections.json')[0].WorkgroupLinks?.map(link => { return link.Links }).flat(1);

    const trainingLinks = [...RepAnalyticsLinks?.map(rplnk => {
        var label = `Reporting & Analytics Workgroups ${rplnk.Extension === "mp4" ? "Video" : "Slide"} - ${rplnk.Label}`
        return { ...rplnk, Label: label }
    }),
        ...AppFlowLinks?.map(rplnk => {
            var label = `Applicant Flow Data Workgroups ${rplnk.Extension === "mp4" ? "Video" : "Slide"} - ${rplnk.Label}`
        return { ...rplnk, Label: label }
    }),
        ...InterconnWGLinks?.map(rplnk => {
            var label = `Interconnections Workgroups ${rplnk.Extension === "mp4" ? "Video" : "Slide"} - ${rplnk.Label}`
        return { ...rplnk, Label: label }
    }),
    ...require('./Training/Cognos/Cognos.json')[0].CognosLinks ?? [],
    ...require('./Training/Cognos/Author/AuthorTraining.json')[0].TrainingLinks ?? [],
    ...require('./Training/Cognos/Consumer/ConsumerTraining.json')[0].TrainingLinks ?? [],
    ...require('./Training/PowerBI/PowerBI.json')[0].PowerBILinks ?? [],
    ...require('./Training/Cognos/JobAids/HiringDataAids.json')[0].JobAidsLinks ?? [],
    ...require('./Training/Cognos/JobAids/ApplicantFlowAids.json')[0].JobAidsLinks ?? [],
    ...require('./Training/Workgroups/ApplicantFlow/ApplicantFlow.json')[0].FlowLinks ?? [],
        ...require('./Training/PowerBI/ReportBriefs.json')[0].ReportBriefsLinks ?? [],
        ...require('./Training/PowerBI/JobAids.json')[0].JobAids_Document_Links ?? [],
        ...require('./Training/PowerBI/JobAids.json')[0].JobAids_Video_Links ?? []].map(item => {
        return {
            ...item, Filter: "Training & Resources"
        };
    });


    // Build 'Release Notes' index
    const releaseNotes = [...require('./ReleaseNotes/Latest.json')[0].NotesLinks ?? [],
    ...require('./ReleaseNotes/LastYear.json')[0].NotesLinks ?? [],
    ...require('./ReleaseNotes/LastTwoYears.json')[0].NotesLinks ?? [],
    ...require('./ReleaseNotes/LastThreeYears.json')[0].NotesLinks ?? []].map(note => {
        return {
            ...note, Label: note.Label + ' - ' + note.SubTitle, SubTitle: '', Filter: "Release Notes"
        };
    })


    // Build 'Interconnections' index
    const { AccordionVideoLinks } = require('./Interconnections/GettingStarted/GettingStarted.json')[0];
    const { MainLinks, SecondaryLinks } = require('./Interconnections/RPI/RPI.json')[0];
    const NHILinks  = require('./Interconnections/NHI/NHI.json')[0].MainLinks??[];
    const { RPIDocLinks } = require('./Interconnections/Documentation.json')[0];

    const interconnData = [...[...AccordionVideoLinks ?? []].map(link => {
        return {
            Label: link.ColumnName, SubTitle: link.ColumnName,
            URL: link.URL,
            Extension: "mp4", Filter: "Interconnections"
        };
    }), ...[...MainLinks ?? [], ...SecondaryLinks ?? [], ...NHILinks ?? [], ...RPIDocLinks ??[]].map(link => {
        return {
            ...link, SubTitle: link.Label, Filter: "Interconnections"
        }
    })]

    function importAll(r) {
        return r.keys().map(r => {
            var apiData = require(`../../public/APIs/${r.slice(2)}`)
            var apiLink = apiData.APIInfo.APIName.replace(/ /g, '');
            var endpointID = 0;
            endPointLinks = endPointLinks.concat(...[apiData.APIInfo.Endpoints?.map(itm => {

                return {
                    Label: itm.RelativeUrl, SubTitle: itm.EndpointDescription, Extension: "page", Filter: "Interconnections",
                    URL: `Interconnections/DataAPIs/Documentation/Details?api=${apiLink}&endPoint=${apiData.APIInfo.APIID + endpointID++}`

                }
            })]);
            return {
                Label: apiData.APIInfo.APIName, SubTitle: apiData.APIInfo.APIDescription,
                URL: `Interconnections/DataAPIs/Documentation/Details?api=${apiLink}`,
                Extension: "page", Filter: "Interconnections"
            };
        });
    }

    const apiDataFiles = importAll(require.context('../../public/APIs/', false, /\.(json)$/));


    // Build 'Help' index
    const helpData1 = [
        ...[...require('./Help/FAQs/DataAPIs.json')[0].AccordionLists ?? []].map(item => {
            return {
                Label: item.Title,
                SubTitle: item.Content?.replace(regex, ""),
                URL: `Help/FAQs/Interconnections/DataAPIs?help=${item.ID}`,
                Extension: "page",
                Filter: "Help"
            };
        }),
        ...[...require('./Help/FAQs/Interconnections.json')[0].AccordionLists ?? []].map(item => {
            return {
                Label: item.Title,
                SubTitle: item.Content?.replace(regex, ""),
                URL: `Help/FAQs/Interconnections?help=${item.ID}`,
                Extension: "page",
                Filter: "Help"
            };
        }),
        ...[...require('./Help/FAQs/PowerBIFAQs.json')[0].AccordionLists ?? []].map(item => {
            return {
                Label: item.Title,
                SubTitle: item.Content?.replace(regex, ""),
                URL: `Help/FAQs/PowerBI?help=${item.ID}`,
                Extension: "page",
                Filter: "Help"
            };
        }),
        ...[...require('./Help/FAQs/AuthenticationFAQs.json')[0].AccordionLists ?? []].map(item => {
            return {
                Label: item.Title,
                SubTitle: item.Content?.replace(regex, ""),
                URL: `Help/FAQs/PowerBI/Authentication?help=${item.ID}`,
                Extension: "page",
                Filter: "Help"
            };
        })
    ];

    const helpData2 = [
        ...[...[...nhiFaqFile[0].FunctionalLists] ?? [], ...[...nhiFaqFile[0].TechnicalLists] ?? []].map(item => {
            return {
                Label: item.Title,
                SubTitle: item.Content?.replace(regex, ""),
                URL: `Help/FAQs/Interconnections/NHI?tab=${item.ID.startsWith('nff') ? 0 : 1}&help=${item.ID}`,
                Extension: "page",
                Filter: "Help"
            };
        }),
        ...[...[...rpiFaqFile[0].FunctionalLists] ?? [], ...[...rpiFaqFile[0].TechnicalLists] ?? []].map(item => {
            return {
                Label: item.Title,
                SubTitle: item.Content?.replace(regex, ""),
                URL: `Help/FAQs/Interconnections/RPI?tab=${item.ID.startsWith('rff') ? 0 : 1}&help=${item.ID}`,
                Extension: "page",
                Filter: "Help"
            };
        })
    ];

    // Combind all indexes into one for jsSearch operations.
    const siteIndexData = {
        allContent: [...homeLinks, ...interconnData, ...trainingLinks, ...releaseNotes, ...helpData1, ...helpData2, ...apiDataFiles, ...endPointLinks],
        options: {
            indexStrategy: "All",
            searchSanitizer: "Lower Case",
            LabelIndex: true,
            SubTitleIndex: true,
            URLIndex: true,
            SearchByTerm: true,
        }
    }


    useEffect(() => {

    });

    return (
        <>

            <ClientSearch contents={siteIndexData.allContent} engine={siteIndexData.options} searchQuery={searchWord} />
        </>
    )




}
export { Search };
