import React, { Component, useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Layout } from './components/Layout';
import './custom.css';
import { Documentation } from './components/Interconnections/Documentation';
import { GettingStarted } from './components/Interconnections/GettingStarted/GettingStarted';
import { InterconnPage } from './components/Interconnections/InterconnPage';
import { InterconnDocs } from './components/Interconnections/InterconnDocs';
import { TaskUpdateAPI } from './components/Interconnections/TaskUpdate/TaskUpdateAPI';
import { Training } from './components/Training/Training';
import { Cognos } from './components/Training/Cognos/Cognos';
import { JobAids } from './components/Training/Cognos/JobAids/JobAids';
import { AFDJobAids } from './components/Training/Cognos/JobAids/ApplicantFlowAids';
import { HDWJobAids } from './components/Training/Cognos/JobAids/HiringDataAids';
import { ReportAuthor } from './components/Training/Cognos/Author/ReportAuthor';
import { AuthorForum } from './components/Training/Cognos/Author/AuthorForum';
import { AuthorTraining } from './components/Training/Cognos/Author/AuthorTraining';
import { ReportConsumer } from './components/Training/Cognos/Consumer/ReportConsumer';
import { ConsumerTraining } from './components/Training/Cognos/Consumer/ConsumerTraining';
import { ConsumerForum } from './components/Training/Cognos/Consumer/ConsumerForum';
import { PowerBI } from './components/Training/PowerBI/PowerBI';
import { PowerBIReportConsumer } from './components/Training/PowerBI/Consumer/ReportConsumer';
import { PowerBIConsumerForum } from './components/Training/PowerBI/Consumer/ConsumerForum';
import { PowerBIConsumerTraining } from './components/Training/PowerBI/Consumer/ConsumerTraining';
import { PowerBIJobAids } from './components/Training/PowerBI/JobAids';
import { PowerBIReportBriefs } from './components/Training/PowerBI/ReportBriefs';
import { Home } from './components/Home';
import { ReleaseNotes } from './components/ReleaseNotes/ReleaseNotes';
import { ReleaseSchedule } from './components/ReleaseNotes/ReleaseSchedule';
import { ReleaseNotesPage } from './components/ReleaseNotes/ReleaseNotesPage';
import { Help } from './components/Help/Help';
import { FAQs } from './components/Help/FAQs/FAQs';
import { FAQPages } from './components/Help/FAQs/FAQPages';
import { DataAPIs } from './components/Interconnections/DataAPIs/DataAPIs';
import { Documentation as DataAPIsDocumentation } from './components/Interconnections/DataAPIs/Documentation/Documentation';
import { APIDetails } from './components/Interconnections/DataAPIs/Documentation/APIDetails';
import { DataDictionary } from './components/Interconnections/DataAPIs/DataDictionary/DataDictionary';
import { PageNotFound } from './components/PageNotFound';
import { RPITechSpecs } from './components/Interconnections/RPI/RPITechSpecs';
import { Workgroups } from './components/Training/Workgroups/Workgroups';
import { WorkgroupPages } from './components/Training/Workgroups/WorkgroupPages';
import { WorkgroupDetails } from './components/Training/Workgroups/WorkgroupDetails';
import { Search } from './components/SearchResults';
import { SubmitTicket } from './components/Help/SubmitTicket/SubmitTicket';


import { MsalProvider, useMsal, useMsalAuthentication, useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { BrowserAuthError, EventType, InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import { msalInstance } from "./index";
import { loginRequest } from './authConfig';
import useLocalStorage from './hooks/useLocalStorage'
//import { Request, Get, withAxios } from 'react-axios';
import { axios } from 'axios'
// import api from './api/authorization'


const Pages = () => {


    // const [count, setCount] = useLocalStorage("count", null);

    // const { login, result, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);

    // useEffect(() => {

    // const callbackId = msalInstance.addEventCallback((event) => {
    //     if ((event.eventType === EventType.LOGIN_SUCCESS || 
    //         event.eventType === EventType.SSO_SILENT_SUCCESS || 
    //         event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
    //         event.payload.account) 
    //     {
    //         const originalSignInAccount = msalInstance.getAllAccounts()
    //                                                   .find((account) => 
    //                                                   account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
    //                                                   account.idTokenClaims.sub === event.payload.idTokenClaims.sub);

    //         console.log(originalSignInAccount.idTokenClaims);
    //         var claims = originalSignInAccount.idTokenClaims;
    //         var userID = claims.userId;
    //         var idp = claims.idp;
    //         var startIndex = idp.indexOf(".net") + 5;
    //         var endValue = idp.lastIndexOf("/");
    //         var htid = idp.substring(startIndex, endValue);
    //         var groups = claims.groups.join("|");
    //         var email = claims.email;
    //         //make call to function to update user in database

    //         api.get(`/api/CreateUser?objectID=${userID}&email=${email}&htid=${htid}&groups=${groups}`)
    //             .then(res => {
    //                 //alert('success')
    //             })
    //             .catch((error) => {
    //                 //alert(error)
    //             });
    //         // return () => {
    //         //     <Get 
    //         //     instance={withAxios.create({})}
    //         //     url="https://func-developerplatform-d-001.azurewebsites.net/api/createuser"
    //         //     params={
    //         //         {
    //         //             objectID: userID, 
    //         //             htid: htid, 
    //         //             email: email,
    //         //             groups: groups
    //         //         }
    //         //     }
    //         //     onSuccess={(response)=>{ alert("success")}}
    //         //     onLoading={()=>{}}
    //         //     onError={(error)=>{alert("failed")}} />

    //         // };
    //     }
    // });

    // if (error instanceof InteractionRequiredAuthError) {

    //     msalInstance.loginRedirect({
    //         ...loginRequest,
    //         prompt:'create',
    //     })
    //     .catch((redirectError) => console.log(redirectError));
    // }

    // return () => {
    //     if (callbackId) {
    //         msalInstance.removeEventCallback(callbackId);
    //     }
    // };

    // }, [error]);

    return (
        <div>
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/Search" element={<Search />} />
                    <Route path="/Interconnections" element={<Documentation />}>
                        <Route path="DataAPIs" element={<DataAPIs />} />
                        <Route path="DataAPIs/DataDictionary" element={<DataDictionary />} />
                        <Route path="DataAPIs/Documentation" element={<DataAPIsDocumentation />} />
                        <Route path="DataAPIs/Documentation/Details" element={<APIDetails />} />
                        <Route path="RPI" element={<InterconnPage ContentFile="./RPI/RPI.json" />} />
                        <Route path="RPI/Documentation" element={<InterconnDocs ContentFile="./RPI/RPI.json" />} />
                        <Route path="RPI/TechSpecs" element={<RPITechSpecs />} />
                        <Route path="NHI" element={<InterconnPage ContentFile="./NHI/NHI.json" />} />
                        <Route path="NHI/Documentation" element={<InterconnDocs ContentFile="./NHI/NHI.json" />} />
                        <Route path="GettingStarted" element={<GettingStarted />} />
                        <Route path="TaskUpdateAPI" element={<TaskUpdateAPI />} />
                    </Route>
                    <Route path="/Training" element={<Training />}>
                        <Route path="Cognos" element={<Cognos />} />
                        <Route path="Cognos/JobAids" element={<JobAids />} />
                        <Route path="Cognos/JobAids/AFD" element={<AFDJobAids />} />
                        <Route path="Cognos/JobAids/HDW" element={<HDWJobAids />} />
                        <Route path="Cognos/ReportAuthor" element={<ReportAuthor />} />
                        <Route path="Cognos/ReportAuthor/AuthorForum" element={<AuthorForum />} />
                        <Route path="Cognos/ReportAuthor/AuthorTraining" element={<AuthorTraining />} />
                        <Route path="Cognos/ReportConsumer" element={<ReportConsumer />} />
                        <Route path="Cognos/ReportConsumer/ConsumerTraining" element={<ConsumerTraining />} />
                        <Route path="Cognos/ReportConsumer/ConsumerForum" element={<ConsumerForum />} />
                        <Route path="PowerBI" element={<PowerBI />} />
                        <Route path="PowerBI/ReportConsumer" element={<PowerBIReportConsumer />} />
                        <Route path="PowerBI/ReportConsumer/ConsumerTraining" element={<PowerBIConsumerTraining />} />
                        <Route path="PowerBI/ReportConsumer/ConsumerForum" element={<PowerBIConsumerForum />} />
                        <Route path="PowerBI/JobAids" element={<PowerBIJobAids />} />
                        <Route path="PowerBI/ReportBriefs" element={<PowerBIReportBriefs />} />
                        <Route path="Workgroups" element={<Workgroups />} />
                        <Route path="Workgroups/ApplicantFlow" element={<WorkgroupPages ContentFile="./ApplicantFlow/ApplicantFlow.json" />} />
                        <Route path="Workgroups/ApplicantFlow/Slides" element={<WorkgroupDetails ContentFile="./ApplicantFlow/ApplicantFlow.json" DataType="pdf" />} />
                        <Route path="Workgroups/ApplicantFlow/Videos" element={<WorkgroupDetails ContentFile="./ApplicantFlow/ApplicantFlow.json" DataType="mp4" />} />
                        <Route path="Workgroups/ReportAnalytics" element={<WorkgroupPages ContentFile="./ReportAnalytics/ReportAnalytics.json" />} />
                        <Route path="Workgroups/ReportAnalytics/Slides" element={<WorkgroupDetails ContentFile="./ReportAnalytics/ReportAnalytics.json" DataType="pdf" />} />
                        <Route path="Workgroups/ReportAnalytics/Videos" element={<WorkgroupDetails ContentFile="./ReportAnalytics/ReportAnalytics.json" DataType="mp4" />} />
                        <Route path="Workgroups/Interconnections" element={<WorkgroupPages ContentFile="./Interconnections.json" />} />
                        <Route path="Workgroups/Interconnections/Slides" element={<WorkgroupDetails ContentFile="./Interconnections.json" DataType="pdf" />} />
                        <Route path="Workgroups/Interconnections/Videos" element={<WorkgroupDetails ContentFile="./Interconnections.json" DataType="mp4" />} />
                    </Route>
                    <Route path="/ReleaseNotes" element={<ReleaseNotes />}>
                        <Route path="ReleaseSchedule" element={<ReleaseSchedule />} />
                        <Route path="TwentyTwentyFive" element={<ReleaseNotesPage ContentFile="./TwentyTwentyFive.json" />} />
                        <Route path="Latest" element={<ReleaseNotesPage ContentFile="./Latest.json" />} />
                        <Route path="LastYear" element={<ReleaseNotesPage ContentFile="./LastYear.json" />} />
                        <Route path="LastTwoYears" element={<ReleaseNotesPage ContentFile="./LastTwoYears.json" />} />
                        <Route path="LastThreeYears" element={<ReleaseNotesPage ContentFile="./LastThreeYears.json" />} />

                    </Route>
                    <Route path="/Help" element={<Help />}>
                        <Route path="FAQs" element={<FAQs />} />
                        <Route path="FAQs/Interconnections" element={<FAQPages ContentFile="./Interconnections.json" DefaultFAQ="gf1" />} />
                        <Route path="FAQs/Interconnections/DataAPIs" element={<FAQPages ContentFile="./DataAPIs.json" DefaultFAQ="daf1" />} />
                        <Route path="FAQs/Interconnections/NHI" element={<FAQPages ContentFile="./NHIFAQs.json" DefaultFAQ="nff1" HasTabs={true} />} />
                        <Route path="FAQs/Interconnections/RPI" element={<FAQPages ContentFile="./RPIFAQs.json" DefaultFAQ="rff1" HasTabs={true} />} />
                        <Route path="FAQs/PowerBI" element={<FAQPages ContentFile="./PowerBIFAQs.json" DefaultFAQ="pf1" />} />
                        <Route path="FAQs/PowerBI/Authentication" element={<FAQPages ContentFile="./AuthenticationFAQs.json" DefaultFAQ="aaf1"  />} />
                        <Route path="SubmitTicket" element={<SubmitTicket />} />
                    </Route>
                    <Route path="/404" element={<PageNotFound />} />
                    <Route path="*" element={<Navigate to="/404" />} />
                </Routes>
            </Layout>
            {/* <AuthenticatedTemplate>
                <Layout>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/Search" element={<Search />} />
                        <Route path="/Interconnections" element={<Documentation />}>
                            <Route path="DataAPIs" element={<DataAPIs />} />
                            <Route path="DataAPIs/DataDictionary" element={<DataDictionary />} />
                            <Route path="DataAPIs/Documentation" element={<DataAPIsDocumentation />} />
                            <Route path="DataAPIs/Documentation/Details" element={<APIDetails />} />
                            <Route path="RPI" element={<RPI />} />
                            <Route path="RPI/Documentation" element={<RPIDocumentation />} />
                            <Route path="RPI/TechSpecs" element={<RPITechSpecs />} />
                            <Route path="NHI" element={<NHI />} />
                            <Route path="NHI/Documentation" element={<NHIDocumentation />} />
                            <Route path="GettingStarted" element={<GettingStarted />} />
                            <Route path="TaskUpdateAPI" element={<TaskUpdateAPI />} />
                        </Route>
                        <Route path="/Training" element={<Training />}>
                            <Route path="Cognos" element={<Cognos />} />
                            <Route path="Cognos/JobAids" element={<JobAids />} />
                            <Route path="PowerBI" element={<PowerBI />} />
                            <Route path="PowerBI/JobAids" element={<PowerBIJobAids />} />
                            <Route path="PowerBI/ReportBriefs" element={<PowerBIReportBriefs />} />
                            <Route path="Workgroups" element={<Workgroups />} />
                                <Route path="Workgroups/ApplicantFlow" element={<ApplicantFlow />} />
                                    <Route path="Workgroups/ApplicantFlow/Slides" element={<FlowSlides />} />
                                    <Route path="Workgroups/ApplicantFlow/Videos" element={<FlowVideos />} />
                                <Route path="Workgroups/ReportAnalytics" element={<ReportAnalytics />} />
                                    <Route path="Workgroups/ReportAnalytics/Slides" element={<ReportSlides />} />
                                    <Route path="Workgroups/ReportAnalytics/Videos" element={<ReportVideos />} />
                        </Route>
                        <Route path="/ReleaseNotes" element={<ReleaseNotes />}>
                            <Route path="Latest" element={<Latest />} />
                            <Route path="LastYear" element={<LastYear />} />
                            <Route path="LastTwoYears" element={<LastTwoYears />} />
                        </Route>
                        <Route path="/Help" element={<Help />}>
                            <Route path="FAQs" element={<FAQs />} />
                            <Route path="FAQs/DataAPIs" element={<DataAPIsFAQs />} />
                            <Route path="FAQs/NHI" element={<NHIFAQs />} />
                            <Route path="FAQs/RPI" element={<RPIFAQs />} />
                        </Route>
                        <Route path="/404" element={<PageNotFound />} />
                        <Route path="*" element={<Navigate to="/404" />} />
                    </Routes>
                </Layout>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
            
            </UnauthenticatedTemplate> */}
        </div>
    )
};

export { Pages };